import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

class About extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="About" description={"About"} lang="en"/>
        <h1>About</h1>
        <p>Last updated: September 21, 2019</p>
        <p>Algorithms-and-Technologies.com is a Website that publishes algorithm implementations in various programming languages.</p>
        <p>This Website was created in August 2019 by Claas Meiners.</p>
        <p>If you want to contact me please write an Email to claasmeiners@googlemail.com.</p>
        <p></p>
      </Layout>
    )
  }
}

export default About
